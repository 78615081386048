.rbc-calendar-wrapper {
  font-weight: 400;
  font-size: 11px;
  color: #566370;
}

@media (max-width: 768px) {
  .calendar-wrapper {
    display: none;
  }
}
