@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html body {
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  svg.icon {
    @apply size-4;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  header.mobile {
    @apply fixed top-0 left-0 w-full h-screen bg-white overflow-auto;
  }

  header.mobile nav {
    @apply flex absolute top-0 left-0 py-2 pt-20 border-t border-t-gray-200 w-full px-4;
  }
}
