@import 'theme/scss/variables';

.assignment-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // text-align: center;
  padding: 0px !important;
  .spinner {
    color: #2264f2;
    margin-left: 50%;
  }
  .table-wrapper {
    & > table {
      width: 100%;
      & > thead {
        & > tr {
          & > th {
            padding: 10px 16px;
            background: $gray--50;
            color: $gray--600;
            font-size: $text-normal;
            font-weight: 500;
            position: sticky;
            top: 0;
          }

          .header-1 {
            width: 272px;
          }
          .header-2 {
            width: 100px;
          }
          .header-3 {
            width: 68px;
            text-align: center;
          }
          .header-4 {
            width: 40px;
          }
        }
      }

      & > tbody {
        & > tr {
          border-bottom: 1px solid $gray--border;

          &:last-child {
            border-bottom: none;
            position: sticky;
            bottom: 0px;
            background-color: #ffffff;
          }

          & > td {
            padding: 16px;
          }

          .colume-1,
          .colume-2 {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
          }

          .colume-sub-1 {
            font-size: $text-normal;
            color: $text-secondary;
            font-weight: 500;
          }

          .colume-3 {
            display: flex;
            justify-content: center;
            & > div {
              background-color: $color-orange--50;
              color: $color-orange;
              width: 68px;
              height: 32px;
              border-radius: 24px;
              text-align: center;
              line-height: 32px;
              font-size: $text-normal;
            }

            .time-medium {
              background-color: #ebebf9;
              color: #5b5bd0;
            }

            .time-high {
              color: #4a9903;
              background-color: #ebf7e1;
            }
          }

          .colume-4 {
            display: flex;
            justify-content: flex-end;
            & > img {
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            }
          }

          .btn-load-more {
            color: $color-blue;
            cursor: pointer;
            text-decoration: none;
            width: fit-content;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .no-assignment {
      height: 100px;
      padding: 45px;
      width: 100%;
      text-align: center;
      position: relative;
      font-size: $text-sm;
      color: $gray--900;
      font-weight: 500;
    }
  }

  .table-wrapper-mb {
    display: none;
  }

  .assignment-text {
    color: #566370;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 16px;
  }
  .new-assignment-btn {
    border-radius: 6px !important;
    .plus-icon {
      float: left;
    }
  }
}

@media (max-width: 768px) {
  .assignment-wrapper {
    .table-wrapper {
      display: none !important;
    }

    .table-wrapper-mb {
      display: block !important;
      .no-assignment {
        height: 100px;
        padding: 45px;
        width: 100%;
        text-align: center;
        position: relative;
        font-size: $text-sm;
        color: $gray--900;
        font-weight: 500;
      }
      .practice-item {
        padding: 16px 16px 8px 16px;
        border: 1px solid #f0f3f4;
        border-radius: 10px;
        margin-bottom: 10px;
        .practice-item-header {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid #e6eaf0;
          align-items: center;
          .header-title {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
          }
          .header-sub-title {
            font-size: $text-normal;
            color: $text-secondary;
            font-weight: 500;
          }
        }

        .wrapper-field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .field__label {
            color: $gray--600;
            font-size: $text-normal;
            font-weight: 500;
          }
          .field__value-date {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
          }
          .field__value-time {
            display: flex;
            justify-content: center;
            & > div {
              background-color: $color-orange--50;
              color: $color-orange;
              width: 68px;
              height: 32px;
              border-radius: 24px;
              text-align: center;
              line-height: 32px;
              font-size: $text-normal;
            }

            .time-medium {
              background-color: #ebebf9;
              color: #5b5bd0;
            }

            .time-high {
              color: #4a9903;
              background-color: #ebf7e1;
            }
          }
        }
      }
    }
  }
}
