.dropdown-toggle {
  display: flex;
  padding: 8px 8px 8px 12px;
  align-items: center;
  height: 36px;
  border: 1px solid #d1d9dd;
  border-radius: 6px;
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  color: #566370;
  cursor: pointer;
  justify-content: space-between;
  .icon {
    margin-left: 6px;
  }
}

.dropdown-menu {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #f0f3f4;
  box-shadow: 0px 3px 9px 1px rgba(86, 99, 112, 0.16);
  width: 200px;
  margin-top: 10px;
  max-height: 520px;
  overflow-y: auto;
  .dropdown-item {
    border-bottom: 1px solid #f0f3f4;
    padding: 12px 16px;
    display: flex;
  }

  .dropdown-item-body {
    max-height: 312px;
    overflow-y: auto;
  }

  .dropdown-item-bottom {
    .icon__group-add {
      transform: rotateY(180deg);
      translate: -2px 4px;
      font-size: 22px;
    }

    .icon {
      color: #2264f2;
      margin-right: 10px;
    }
  }

  .text {
    color: #0f1825;
    font-weight: 500;
    font-size: 13px;
  }
}
