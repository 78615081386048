@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.input-field-group {
  margin-bottom: 16px;
  position: relative;

  :global {
    .react-datepicker-wrapper {
      display: block;
    }

    .input-group-text {
      border-color: #d1d9dd;
      border-left-width: 0;
      background: none;
    }
  }
}

.input-field-input {
  &:global(.form-control) {
    height: 44px;
    border-color: #d1d9dd;
    font-size: 13px;
    border-radius: 6px;
    box-shadow: none !important;
  }

  &:focus + :global(.input-group-text) {
    border-color: #86b7fe;
    outline: 0;
  }

  &:global(.is-invalid + .input-group-text) {
    border-color: $form-invalid-color;

    svg {
      color: $form-invalid-color;
    }
  }

  &:not(:last-child) {
    border-right-width: 0;
  }
}

.calendar-icon {
  color: #2264f2;
  cursor: pointer;
  height: 30px;
  padding: 6px;
  width: 30px;
}

.clear-calendar-icon {
  color: #566370;
  cursor: pointer;
  height: 30px;
  padding: 6px;
  width: 30px;

  &:hover {
    background: #e6eaf0;
    border-radius: 50%;
  }
}

.calendar:global(.react-datepicker) {
  border-radius: 10px;
  box-shadow: 0px 3px 9px 1px rgba(52, 64, 76, 0.32);
  border: none;
  font-family: 'Inter';
  :global(.react-datepicker__day) {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    &:hover {
      background: #e9f0fe;
      color: #2264f2;
    }
  }

  :global(.react-datepicker__day--selected) {
    background: #2264f2 !important;
    color: white !important;
  }
  :global(.react-datepicker__day-name) {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-weight: 500;
    font-size: 14px;
  }
  :global(.react-datepicker__header) {
    background-color: white;
    border-bottom: none;
    padding-bottom: 0;
  }
  :global(.react-datepicker__month) {
    margin-top: 0;
    position: relative;
  }
  :global(.react-datepicker__current-month) {
    font-weight: 500;
    font-size: 16px;
    color: #192638;
    height: 40px;
    padding-top: 10px;
  }
  :global(.react-datepicker__navigation) {
    top: 20px;
  }
}
