.confirm-modal-body {
  padding: 24px;
}

.send-message-modal-user {
  display: flex;
  align-items: center;
  .student-avatar {
    margin-right: 10px;
  }
  .student-info {
    .first-title {
      font-weight: 500;
      font-size: 14px;
      color: #0f1825;
    }
    .sub-title {
      color: #566370;
      font-weight: 400;
      font-size: 13px;
    }
  }
}
.send-message-input-wrapper {
  margin-bottom: 0;

  .send-message-input-field {
    border: none;
    height: 160px !important;
    padding: 16px 24px !important;
    resize: none;
    border-radius: 0 !important;
  }
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .content-modal-mb {
      height: 100vh !important;
    }
  }
}
