@import 'theme/scss/variables';
.wrapper-event {
  font-size: $text-xs;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  line-height: 19px;
  min-height: 19px;
  .event__icon-group {
    margin-bottom: 2px;
    margin-right: 6px;
  }

  .event__icon-request {
    margin-bottom: 4px;
    margin-right: 6px;
    color: $color-blue !important;
  }
}
