.main-layout {
  height: 100%;
  display: flex;
  background-color: white;
  .side-bar {
    height: 100%;
    width: 246px;
    position: fixed;
    background-color: #f7f9fc;
    padding-top: 27px;
    padding-bottom: 27px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // @include media-breakpoint-down(md) {
    //   width: 70px;
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }
    .side-bar-heading {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 24px;
      padding-left: 12px;
      color: #41474c;
      // @include media-breakpoint-down(md) {
      //   display: none;
      // }
    }
    .side-bar-items {
      .item-link-wrapper {
        text-decoration: none;
        height: 40px;
        width: 100%;
        border-radius: 12px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        text-decoration: none;
        color: #7b8698;
        font-size: 14px;
        transition: none;
        cursor: pointer;
        // @include media-breakpoint-down(md) {
        //   justify-content: center;
        // }
        &:hover {
          color: none;
        }
        &.active {
          background-color: #2264f2;
          color: white;
        }
        .icon-wrapper {
          margin-right: 12px;
          display: flex;
          align-items: center;
          // @include media-breakpoint-down(md) {
          //   margin-right: 0px;
          // }
        }
        .item {
          // @include media-breakpoint-down(md) {
          //   display: none;
          // }
        }

        svg {
          height: 1.125rem;
          width: 1.125rem;
        }
      }
    }
    .side-bar-footer {
      .side-bar-items {
        .item-link-wrapper {
          &.active {
            background-color: #2264f2;
            color: white;
          }
        }
      }
    }
  }

  // Small devices (landscape phones, 768px and up)
  @media (max-width: 768px) {
    .side-bar {
      display: none !important;
    }
  }

  .main-area {
    width: 100%;
    padding-left: 246px;
    height: 100%;
    // @include media-breakpoint-down(md) {
    //   padding-left: 70px;
    // }
    .top-bar {
      height: 72px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 16px;
      padding-right: 16px;
      border-bottom: 1px solid #e6eaf0;
    }
    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: blue;
      cursor: pointer;
      // @include background-image(40px, 40px);
    }
  }

  @media (max-width: 768px) {
    .main-area {
      padding-left: 0px !important;
    }
  }
}
