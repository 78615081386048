:global {
  .pac-container {
    z-index: 1999;
  }

  .modal {
    .btn-close {
      opacity: 1;
      padding: 20px;
      zoom: 0.8;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
    }

    .form-group {
      padding: 0.5em 0;
    }

    .form-control {
      border-color: #d1d9dd;
      border-radius: 6px;
      font-size: 13px;
      height: 44px;
      line-height: 20px;
      padding: 10px 14px;
    }
  }

  .organizations {
    font-size: 13px;

    .material-icons-outlined {
      font-size: 1.5em;
      vertical-align: bottom;
    }

    .btn {
      --bs-btn-border-radius: 40px;
      --bs-btn-font-size: 13px;
      --bs-btn-padding-x: 12px;
      --bs-btn-padding-y: 9px;
      display: flex;
      gap: 6px;
    }

    .nav {
      --bs-nav-underline-gap: 8px;
      border-bottom: 1px solid #e6eaf0;

      .nav-link {
        align-items: center;
        color: inherit;
        display: flex;
        gap: 10px;
        padding: 0 10px 10px;

        &.active {
          border-bottom-color: var(--bs-primary);
          font-weight: normal;
        }
      }
    }

    .card {
      border-color: #d1d9dd;

      &-bg {
        border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
        background:
          conic-gradient(at 90% 105%, transparent 50%, rgb(128, 173, 255) 75%, rgb(145, 128, 255) 80%, transparent)
            repeat scroll 0% 0%,
          rgba(0, 0, 0, 0)
            conic-gradient(at 50% -5%, rgb(128, 237, 255) 25%, rgb(252, 255, 193), rgb(244, 105, 169) 75%) repeat scroll
            0% 0%;
        height: 10vw;
        width: 100%;
      }

      .dropdown {
        &-toggle {
          color: white;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      &-title {
        margin: 0;
        padding-bottom: 16px;
      }

      &-text {
        font-size: 14px;
        padding-top: 16px;
      }
    }

    .dropdown {
      font-size: 13px;

      &-toggle {
        border-color: transparent !important;
        border-radius: 10px;

        &:after {
          display: none;
        }

        &:hover {
          background: #e6eaf0;
        }

        &:active,
        &.show {
          background: #e9f0fe;
        }
      }

      &-menu {
        font-size: 13px;
      }
    }
  }
}
