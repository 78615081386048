@media (max-width: 768px) {
  .filter-students-mb {
    padding: 0px !important;
    .filter-item {
      padding: 18px 16px 0px;
      .title {
        font-weight: 600;
        font-size: 15px;
        color: #0f1825;
        margin-bottom: 0px;
      }
    }
    .wrapper-item-groups {
      max-height: 178px;
      overflow-y: auto;
      padding: 0px 16px;
      border-bottom: 1px solid #f0f3f4;
      .filter-item__group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
        .group__label {
          font-weight: 400;
          font-size: 13px;
          color: #0f1825;
          margin-bottom: 0px;
        }
      }
    }
    .filter-item-bottom {
      padding: 18px 16px 0px;
      .item-handle-group {
        padding-bottom: 16px;
        .icon__group-add {
          transform: rotateY(180deg);
          translate: -2px 4px;
          font-size: 22px;
        }

        .icon {
          color: #2264f2;
          margin-right: 10px;
        }
      }
    }
    .button-apply-wrapper {
      padding: 0px 16px;
      margin-bottom: 18px;
      .button-apply {
        width: 100%;
        border-radius: 58px !important;
      }
    }
  }
}
