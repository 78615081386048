.settings-wrapper {
  padding: 24px 76px;
  .setting-item {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    width: 700px;
    &__label {
      font-weight: 500;
      font-size: 13px;
      color: #0f1825;
      margin-bottom: 0px;
      margin-right: 80px;
      width: 160px;
    }

    &__value {
      width: 350px;
      .input-field-wrapper {
        margin-bottom: 0px !important;
        .input-field {
          padding-top: 6px !important;
          height: auto;
        }
      }
      .desc-input {
        color: #999;
        font-size: 13px;
        margin-bottom: 0px;
        padding: 8px 16px 0px;
      }

      .group-dropdown {
        width: 100%;
      }
    }
  }

  .title-wrapper {
    font-weight: 600;
    font-size: 16px;
    color: #0f1825;
    padding: 14px 0px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 48px;
  }

  .footer-wrapper {
    text-align: right;
    width: 700px;
    margin-top: 16px;
    margin-bottom: 32px;
    .btn-submit {
      border-radius: 6px !important;
    }
  }
}
