.dropdown-toggle {
  display: flex;
  padding: 8px 8px 8px 12px;
  align-items: center;
  height: 36px;
  border: 1px solid #d1d9dd;
  border-radius: 6px;
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  color: #566370;
  cursor: pointer;
  justify-content: space-between;
  .icon {
    margin-left: 6px;
  }
  .icon-error {
    color: #dc3545;
    font-size: 14px;
  }
  &:hover {
    border: 1px solid #abb2b6;
  }
}

.dropdown-toggle__error {
  border: 1px solid #dc3545 !important;
}

.dropdown-menu {
  background: #ffffff;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 3px 9px 1px rgba(86, 99, 112, 0.16);
  overflow-y: auto;
  max-height: 300px;
  .dropdown-item {
    height: 40px;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 13px;
    color: #0f1825;
    transition: 0s;
    &:active {
      color: white;
    }
  }
}

.block {
  display: block !important;
}
