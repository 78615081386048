@import 'theme/scss/variables';

.feeds-wrapper {
  margin: 0 auto;
  max-width: 832px;
  padding: 24px 76px;
  .feeds-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .feeds-header__left {
      font-weight: 500;
      font-size: 15px;
      color: #0f1825;
    }
    .feeds-header__right {
      display: flex;
      .export-wrapper {
        display: flex;
      }
    }
  }

  .spinner-wrapper {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
      color: #2264f2;
    }
  }

  .not-found {
    height: 300px;
    width: 100%;
    color: #2264f2;
    font-weight: 500;
    text-align: center;
  }
}

@media (max-width: 868px) {
  .feeds-wrapper {
    .feeds-header {
      display: block;
      .feeds-header__right {
        justify-content: space-between;
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 342px) {
  .feeds-wrapper {
    .feeds-header {
      display: block;
      .feeds-header__right {
        .export-wrapper {
          margin-bottom: 16px;
        }
      }
    }
  }
}
