.wrapper-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  .header-left {
    display: flex;
    .header-info {
      margin-left: 10px;

      .info__name {
        color: #141416;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px !important;
        margin-top: 2px;
        cursor: pointer;
      }

      .info__time {
        color: #777e90;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0px !important;
      }
    }
  }
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: #777e90;
  }
}
