@import 'theme/scss/variables';
.billing-container {
  padding: 24px 76px;

  .summary-wrapper {
    .summary-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .summary-header__left {
        font-weight: 500;
        font-size: 15px;
        color: #0f1825;
      }
      .summary-header__right {
        display: flex;
        align-items: center;
        .toolbar-wrapper {
          margin-right: 16px;
          display: flex;
          justify-content: space-between;
          .toolbar-label {
            color: $gray--900;
            font-weight: 500;
            margin: 0px 20px;
            font-size: 15px;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            min-width: 100px;
            text-align: center;
            line-height: 36px;
          }

          .toolbar-icon {
            width: 36px;
            height: 36px;
            cursor: pointer;
            background-color: #f7f9fc;
            border-radius: 50%;
            color: $gray--500;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .new-invoice-btn {
          margin-left: 16px;
          .plus-icon {
            float: left;
          }
        }
      }
    }
    .summary-overview {
      margin: 16px 0px;
      .col-left-wrapper,
      .col-right-wrapper {
        padding: 0px;
        .summary-overview__left,
        .summary-overview__right {
          width: 100%;
          margin: 0px;
          border: 1px solid #e6eaf0;
          border-radius: 10px;
          .summary-item {
            padding: 24px;
            border-right: 1px solid #e6eaf0;
            .summary-item__label {
              font-weight: 500;
              font-size: 12px;
              color: #596579;
              margin-bottom: 8px;
            }
            .summary-item__value {
              font-weight: 500;
              font-size: 16px;
              color: #0f1825;
              margin-bottom: 0px;
            }
          }
          .summary-item:last-child {
            border: none;
          }
        }
      }
      .col-left-wrapper {
        padding-right: 20px;
      }
    }
    .summary-chart {
      border: 1px solid #e6eaf0;
      padding: 24px;
      border-radius: 10px;
      margin-bottom: 40px;
      .chart {
        max-height: 300px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .billing-container {
    padding: 0px 15px;
    .summary-wrapper {
      .summary-header {
        display: block;
        .summary-header__left {
          margin-top: 24px;
        }
        .summary-header__right {
          margin-top: 16px;
          justify-content: space-between;
          .toolbar-wrapper {
            margin-right: 8px;
            .toolbar-label {
              margin: 0px 8px;
            }
          }
        }
      }
    }

    .col-left-wrapper,
    .col-right-wrapper {
      padding: 0px;
      .summary-overview__left,
      .summary-overview__right {
        margin-top: 16px !important;
      }
      .summary-overview__right .summary-item:nth-last-child(2) {
        border: none !important;
      }
      .summary-overview__right .summary-item:last-child {
        border-top: 1px solid #e6eaf0 !important;
      }
    }
    .col-left-wrapper {
      padding-right: 0px !important;
    }
  }
}

@media (max-width: 534px) {
  .billing-container {
    .summary-wrapper {
      .summary-header {
        .summary-header__right {
          .new-invoice-btn {
            display: none;
          }
        }
      }
    }
  }
}
