.overview-wrapper {
  .spinner {
    color: #2264f2;
    margin-left: 50%;
  }
  .top-area {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .left-item {
      display: flex;
      .item-item {
        &:first-child {
          border-right: 1px solid #f0f3f4;
          padding-right: 36px;
        }
        &:last-child {
          padding-left: 24px;
        }
        .item-title {
          font-weight: 500;
          font-size: 12px;
          color: #596579;
        }
        .item-value {
          font-weight: 500;
          font-size: 16px;
          color: #0f1825;
        }
      }
    }
  }
  .wrapper-chart {
    max-height: 300px;
    margin-top: 44px;
    .chart {
      max-height: 300px;
    }
  }
}

@media (max-width: 768px) {
  .overview-wrapper {
    padding: 16px !important;
  }
}
