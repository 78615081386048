@import 'theme/scss/variables';

.invoices-wrapper {
  .invoices-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .invoices-header__left {
      font-weight: 500;
      font-size: 15px;
      color: #0f1825;
    }
    .invoices-header__right {
      display: flex;
      .search-input {
        width: 224px;
        margin-right: 10px;
        min-height: 36px !important;
      }
      .export-wrapper {
        display: flex;
        .export-btn {
          border-radius: 6px !important;
          margin-right: 10px;
          border: 1px solid #d1d9dd;
          color: #0f1825;
          &:hover {
            color: #fff;
          }
          .export-btn-icon {
            float: left;
            margin-right: 8px;
            margin-top: 3px;
          }
        }
      }
    }
  }
  .table-invoices-wrapper {
    border-radius: 10px;
    border: 1px solid #f0f3f4;
    & > table {
      width: 100%;
      & > thead {
        & > tr {
          & > th {
            padding: 10px 0px 10px 16px;
            background: $gray--50;
            color: $gray--600;
            font-size: $text-normal;
            font-weight: 500;
            position: sticky;
            top: 0;
          }

          .header-checkbox {
            width: 0px;
          }
          .header-description {
            width: 200px;
          }
          .header-status {
            text-align: center;
          }
          .header-preview {
            text-align: center;
          }
        }
      }

      & > tbody {
        & > tr {
          border-bottom: 1px solid $gray--border;

          &:last-child {
            border-bottom: none;
          }

          & > td {
            padding: 16px 0px 16px 16px;
          }

          .colume-1,
          .colume-2 {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
          }

          .colume-sub-1 {
            font-size: $text-normal;
            color: $text-secondary;
            font-weight: 500;
          }

          .colume-info {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }

          .colume-info,
          .colume-note,
          .colume-date,
          .colume-money {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
          }

          .colume-status-wrapper {
            font-size: $text-sm;
            text-align: center;
          }

          .colume-status {
            &-cancelled {
              color: $color-red;
            }

            &-sent {
              color: $color-green;
            }

            &-paid {
              color: $color-blue;
            }

            &-unpaid {
              color: $text-secondary;
            }

            &-late {
              color: $color-orange;
            }
          }

          .colume-preview {
            font-size: $text-sm;
            font-weight: 500;
            text-align: center;
          }

          .colume-dots {
            display: flex;
            justify-content: flex-end;
            padding-right: 16px;
            .icon {
              cursor: pointer;
              &:hover {
                & > path {
                  fill: $color-blue;
                }
              }
            }
            .spinner {
              color: #2264f2;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .spinner-wrapper {
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner {
        color: #2264f2;
      }
    }
  }
}

@media (max-width: 868px) {
  .invoices-wrapper {
    .invoices-header {
      display: block;
      .invoices-header__right {
        justify-content: space-between;
        margin-top: 16px;
        .search-input {
          width: 124px;
        }
      }
    }
  }
}

@media (max-width: 342px) {
  .invoices-wrapper {
    .invoices-header {
      display: block;
      .invoices-header__right {
        .search-input {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 342px) {
  .invoices-wrapper {
    .invoices-header {
      display: block;
      .invoices-header__right {
        .export-wrapper {
          margin-bottom: 16px;
        }
      }
    }
  }
}
