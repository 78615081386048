.note-detail-wrapper {
  border-left: 1px solid #e0e0e0;
  min-height: 650px;
  padding-left: 16px;
  .note-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    &__avatar {
      margin-right: 10px;
    }
    &__name {
      font-weight: 500;
      font-size: 18px;
      color: #0f1825;
      margin-bottom: 0px;
    }
  }

  .note-detail-body {
    &__item {
      .item-month {
        font-weight: 500;
        font-size: 18px;
        color: #0f1825;
        margin-bottom: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;
      }

      .item-content {
        padding: 0px 0px 16px 0px;
        position: relative;
        .icon-more {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: -4px;
          &:hover {
            color: #2264f2;
          }
          .icon-options {
            margin-right: 4px;
            margin-bottom: 2px;
          }
        }
        .content-description {
          font-weight: 500;
          font-size: 13px;
          color: #0f1825;
          margin-bottom: 16px;
          padding-right: 40px;
        }
        .content-created {
          font-size: 13px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .note-edit-wrapper {
    display: flex;
    .note-edit-field {
      margin-right: 8px;
      width: 100%;
    }
    .note-edit-btn {
      border-radius: 6px;
      height: 44px;
      width: 100px;
    }
    .note-edit-icon {
      margin-top: 4px;
      margin-left: 4px;
      cursor: pointer;
      &:hover {
        color: #2264f2 !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .note-detail-wrapper {
    margin-top: 32px;
    border-left: none;
    padding-left: 0px;
  }
}
