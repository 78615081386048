.input-field-group {
  margin-bottom: 16px;
  position: relative;
}

.input-field-input:global(.form-control) {
  min-height: 44px;
  border: 1px solid #d1d9dd;
  font-size: 13px;
  border-radius: 6px;
  box-shadow: none !important;
  padding-top: 16px;
  &.small {
    height: 36px;
    padding-top: 6px;

    &:focus + .input-field-label {
      display: none;
    }
  }
  &:focus + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }
  &.has-value + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }

  .has-add-on {
    border-radius: 0px 6px 6px 0px !important;
  }
}

.input-field-label {
  font-size: 14px;
  font-weight: 500;
  font-size: 13px;
  color: #566370;
  font-weight: 500;
  position: absolute;
  top: 13px;
  left: 13px;
  transition: 0.15s;
  cursor: text;
  user-select: none;
  &.small {
    display: none !important;
  }
}

.input-field-wrapper {
  display: flex;
  width: 100%;
}

.text-area-input {
  height: 100px;
}

.form-feedback {
  font-size: 13px;
}

.input-field-add-on {
  color: #999 !important;
  font-size: 13px !important;
  background-color: #f5f5f5 !important;
}
