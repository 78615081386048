.goal-modal-body {
  padding: 24px;
  .description-input {
    height: 80px;
  }
  .sub-title {
    color: #566370;
    font-weight: 500;
    font-size: 13px;
  }
  .goal-list {
    display: flex;
    margin-top: 16px;
    overflow-x: auto;
    padding: 16px 0px;
    .goal-item {
      display: flex;
      align-items: center;
      height: 36px;
      background: #e9f0fe;
      border-radius: 30px;
      margin-right: 10px;
      padding: 12px;
      color: #2264f2;
      font-weight: 500;
      font-size: 13px;
      cursor: pointer;
      &.active {
        background-color: #2264f2;
        color: white;
      }
      .icon {
        margin-right: 10px;
      }
      .title {
        width: max-content;
      }
    }
  }
  .duration-units {
    width: 100%;
    height: 44px;
  }
  .interval {
    width: 100%;
    height: 44px;
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .goal-modal-body {
      padding: 20px 15px !important;
    }
    .content-modal-mb {
      height: 100vh !important;
    }
  }
}

:global {
  .form-control {
    &.form-select {
      color: #566370;
      font-size: 13px;
      height: 44px;
    }
  }
}
