@import 'theme/scss/variables';
.add-lesson-modal {
  .add-lesson-modal-body {
    padding: 24px;
    .section {
      margin-bottom: 10px;
    }

    .category-dropdown {
      width: 100%;
      .icon-dot {
        margin-right: 6px;
      }

      .icon-dot--red {
        color: $color-red;
      }

      .icon-dot--green {
        color: $color-green;
      }

      .icon-dot--blue {
        color: $color-blue;
      }

      .category-dropdown-toggle {
        width: 100%;
        height: 44px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .add-lesson-modal-body {
      padding: 24px 15px;
    }
    .content-modal-mb {
      height: 100vh !important;
    }
  }
}
