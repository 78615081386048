.students-wrapper {
  margin-bottom: 36px;
  padding-left: 24px;
  .students-header {
    font-weight: 500;
    font-size: 15px;
    color: #0f1825;
    margin-bottom: 16px;
  }

  .btn-view-students {
    width: 100%;
    text-align: center;
    display: block;
    text-decoration: none;
    height: 44px;
    line-height: 44px;
    background: #2264f20d;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    &:hover {
      background: #2264f21a;
    }
  }

  .no-student {
    border: 1px solid #f0f3f4;
    border-radius: 10px;
    padding: 44px;
    display: flex;
    justify-content: center;
    .no-student-body {
      width: 382px;
      text-align: center;
      .no-student__title {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #0f1825;
      }
      .no-student__desc {
        font-size: 13px;
        text-align: center;
        color: #596579;
      }
    }
  }
}

@media (max-width: 768px) {
  .students-wrapper {
    padding: 0px 15px;
  }
}
