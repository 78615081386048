@import 'theme/scss/variables';
.container {
  .messages-wrapper {
    margin: 0px;
    .left-wrapper {
      padding: 10px 16px;
      .tabs {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        height: 44px;
        border-radius: 54px;
        background: #f7f9fc;
        line-height: 48px;
        padding: 6px 3px;
        margin-bottom: 10px;
        .tab-item {
          width: 33%;
          text-align: center;
          color: #7b8698;
          font-weight: 500;
          font-size: 13px;
          height: 32px;
          cursor: pointer;
          line-height: 32px;
          margin-bottom: 0px;
        }

        .tab-item--active {
          background: #ffffff !important;
          border: 1px solid #e6eaf0;
          border-radius: 49px;
          color: #192638 !important;
        }
      }
    }
    .right-wrapper {
      padding: 0px;
      height: calc(100vh - 72px);
      border-left: 1px solid #f0f3f4;
    }
  }
}

.spinner-wrapper {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    color: #2264f2;
  }
}
