@import 'theme/scss/variables';

.container-drawer-lesson {
  margin-top: auto;
  width: 380px !important;

  @media (max-width: 768px) {
    width: 100% !important;
    height: 100%;
  }
}

.container-calendar {
  padding: 9px 24px;
  .rbc-calendar {
    min-height: 690px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .right-wrapper {
      display: flex;
      align-items: center;
      .add-calendar-btn {
        margin-right: 24px;
        border-radius: 6px !important;
      }
      .group-dropdown {
        margin-right: 8px;
      }
    }
  }
  .spinner-wrapper {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
      color: #2264f2;
    }
  }
}

.container-drawer {
  border: 1px solid #f0f3f4;
  .wrapper-header {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #f0f3f4;
    .header__title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .edit-icon {
      cursor: pointer;
      color: #2264f2;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .wrapper-fields {
    padding: 24px;
    border-bottom: 1px solid #f0f3f4;
    .field {
      display: flex;
      margin: 8px 0px;
      .field__name {
        width: 90px;
        color: #566370;
        font-size: 13px;
      }

      .field__value {
        margin-left: 27px;
      }
    }
  }

  .wrapper-description {
    padding: 24px;
    border-bottom: 1px solid #f0f3f4;
  }

  .wrapper-links {
    padding: 24px;
    border-bottom: 1px solid #f0f3f4;
    .link__header {
      display: flex;
      justify-content: space-between;
    }
  }

  .wrapper-question {
    padding: 24px;
    display: flex;
    .question__input {
      width: 100%;
      margin-left: 8px;
    }
  }

  .label {
    font-size: 13px;
    color: #566370;
    margin-bottom: 0px;
  }

  .wrapper-member {
    display: flex;
  }

  .member-avatar {
    margin-right: 6px;
  }

  .text {
    font-size: 13px;
    color: #0f1825;
    margin-bottom: 0px;
  }

  .icon-dot {
    margin-right: 6px;
  }

  .icon-dot--red {
    color: $color-red;
  }

  .icon-dot--green {
    color: $color-green;
  }

  .icon-dot--blue {
    color: $color-blue;
  }
}
