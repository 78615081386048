@import 'theme/scss/variables';

.wrapper {
  margin: 0 auto;
  max-width: 832px;
  padding: 24px 76px;
  .spinner-wrapper {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
      color: #2264f2;
    }
  }
}

@media (max-width: 400px) {
  .wrapper {
    padding: 24px 30px;
  }
}
