.student-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  height: 56px;
  border: 1px solid #e6eaf0;
  border-radius: 10px;
  margin-bottom: 16px;
  .left-wrapper {
    display: flex;
    align-items: center;
    .item-checkbox {
      margin-right: 16px;
    }
    .student-avatar {
      margin-right: 10px;
    }
    .student-info {
      width: 300px;
      .first-title {
        font-weight: 600;
        font-size: 14px;
        color: #0f1825;
        cursor: default;
        &__allow-click {
          cursor: pointer;
        }
      }

      .sub-title {
        font-weight: 400;
        font-size: 11px;
        color: #596579;
      }
      .student-time-practiced-mb {
        display: none;
      }
    }
    .student-time-practiced {
      padding: 0px 10px;
      font-weight: 500;
      font-size: 14px;
      color: #596579;
      width: 100px;
    }
    .timechart-wrapper {
      width: 215px;
      display: flex;
      justify-content: center;
      .resend-btn {
        .icon {
          margin-left: 6px;
        }
      }
      .invite-resent-text {
        font-size: 13px;
        font-weight: 600;
        color: gray;
      }
    }
  }
  .timechart-wrapper-mb {
    display: none;
  }
  .right-wrapper {
    display: flex;
    align-items: center;
    .more-icon {
      cursor: pointer;
    }
  }
}

@media (max-width: 1336px) {
  .student-item {
    padding: 12px;
    .left-wrapper {
      .student-info {
        max-width: 100px;
        min-width: 50px;
        width: auto;
      }
      .timechart-wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .student-item {
    padding: 12px;
    .left-wrapper {
      .student-info {
        max-width: 300px;
        min-width: 200px;
        width: auto;
        .student-time-practiced-mb {
          display: block;
          font-weight: 500;
          font-size: 12px;
          color: #596579;
        }
      }
      .student-time-practiced,
      .timechart-wrapper {
        display: none;
      }
    }

    .timechart-wrapper-mb {
      display: block !important;
      .invite-resent-text {
        font-size: 13px;
        font-weight: 600;
        color: gray;
      }
    }
  }
}
