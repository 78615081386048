@import 'theme/scss/variables';

.invoices-wrapper {
  padding: 0px !important;
  .spinner {
    color: #2264f2;
    margin-left: 50%;
  }
  .no-invoice {
    height: 100px;
    padding: 45px;
    width: 100%;
    text-align: center;
    position: relative;
    font-size: $text-sm;
    color: $gray--900;
    font-weight: 500;
  }
  .table-wrapper {
    & > table {
      width: 100%;
      & > thead {
        & > tr {
          .header-1 {
            width: 180px;
          }
          .header-2 {
            width: 160px;
          }
          .header-3 {
            width: 68px;
            display: block;
          }
          .header-4 {
            width: 40px;
          }
        }
      }

      & > tbody {
        & > tr {
          border-bottom: 1px solid $gray--border;

          &:last-child {
            border-bottom: none;
            position: sticky;
            bottom: 0px;
            background-color: #ffffff;
          }

          & > td {
            padding: 16px;
          }

          .colume-1,
          .colume-2 {
            font-size: $text-sm;
            color: $gray--900;
            font-weight: 500;
            .colume-2-item {
              margin-bottom: 0px;
            }
            .colume-2-item-mb {
              display: none;
            }
          }

          .colume-sub-1 {
            font-size: $text-normal;
            color: $text-secondary;
            font-weight: 500;
          }

          .colume-3 {
            color: $text-secondary;
            font-size: $text-sm;
          }

          .colume-3--active {
            color: $color-blue;
            font-size: $text-sm;
          }

          .colume-4 {
            display: flex;
            justify-content: flex-end;
            .icon {
              cursor: pointer;
              &:hover {
                & > path {
                  fill: $color-blue;
                }
              }
            }
          }

          .btn-load-more {
            color: $color-blue;
            cursor: pointer;
            width: fit-content;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .invoices-wrapper {
    .table-wrapper {
      & > table {
        & > thead {
          & > tr {
            .header-3 {
              width: 68px;
              display: none;
            }
          }
        }

        & > tbody {
          .colume-2 {
            .colume-2-item,
            .colume-2-item-mb {
              margin-bottom: 0px !important;
              display: block !important;
            }
          }
          .wrapper-colume-3 {
            display: none;
          }
        }
      }
    }
  }
}
