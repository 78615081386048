@import 'theme/scss/variables';
.assignments-wrapper {
  padding: 24px 76px;
  .header {
    display: flex;
    justify-content: space-between;
    .search-input {
      width: 224px;
    }
    .right-wrapper {
      display: flex;
      align-items: center;
      .date-wrapper {
        display: flex;
        justify-content: center;
        margin-right: 24px;
        align-items: center;

        .date-label {
          color: $gray--900;
          font-weight: 500;
          margin: 0px 20px;
          font-size: 15px;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
          min-width: 100px;
          text-align: center;
        }

        .date-icon {
          width: 36px;
          height: 36px;
          cursor: pointer;
          background-color: #f7f9fc;
          border-radius: 50%;
          color: $gray--500;
          text-align: center;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
          &:hover {
            opacity: 0.8;
          }
          .date-icon-left {
            margin-bottom: -8px;
            margin-right: 2px;
          }

          .date-icon-right {
            margin-bottom: -8px;
            margin-left: 2px;
          }
        }
      }
      .new-assignment-btn {
        margin-right: 10px;
        border-radius: 6px !important;
        padding: 8px 12px !important;
        .plus-btn-icon {
          float: left;
          margin-right: 8px;
        }
      }
    }
  }

  .spinner {
    color: #2264f2;
    margin-top: 200px;
    margin-left: 50%;
  }
}
