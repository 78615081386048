.student-details-container {
  padding: 44px 76px;

  .wrapper-tab {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    height: 48px;
    border-radius: 54px;
    background: #f7f9fc;
    line-height: 48px;
    padding: 6px 0px;

    .tab-item {
      width: 48%;
      text-align: center;
      color: #7b8698;
      font-weight: 500;
      font-size: 13px;
      height: 36px;
      cursor: pointer;
      line-height: 36px;
    }

    .tab-item--active {
      background: #ffffff !important;
      border: 1px solid #e6eaf0;
      border-radius: 49px;
      color: #192638 !important;
    }
  }
}

@media (max-width: 768px) {
  .student-details-container {
    padding: 24px 15px;
    .wrapper-tab {
      margin: 24px 0px;
    }
  }
}
