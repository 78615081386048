.bg-hero-section {
  background: url('/src/theme/img/bg-line.svg'),
    linear-gradient(180deg, rgba(210, 238, 253, 1), rgba(243, 242, 248, 1), rgba(251, 251, 252, 1));
}

.bg-hero-position {
  background-position: 65% 25%;
}

#slider img {
  width: 100%;
}

.animate-progress {
  animation: progress 5s linear infinite;
}

.bg-gradient-slider {
  background: linear-gradient(180deg, transparent 0%, #ffffff 100%);
}

.stack-images:after,
.stack-images:before {
  content: '';
  @apply absolute left-1/2 -translate-x-1/2 max-md:hidden;
  @apply size-full rounded-3xl;
  @apply border border-[#0E3F7E] border-opacity-10;
}

.stack-images:after {
  @apply max-w-[1320px] top-[30px];
}
.stack-images:before {
  @apply max-w-[1360px] top-[60px];
}

.bg-promotion-section {
  background: url('/src/theme/img/bg-promo.svg'),
    radial-gradient(circle, rgba(34, 166, 242, 0.12), rgba(34, 166, 242, 0.18));
}

.bg-promotion-section img {
  width: 100%;
}

.bg-fade-gradient {
  background: linear-gradient(180deg, #fafafa 20%, transparent 100%);
}

.bg-fade-gradient-medium {
  background: linear-gradient(180deg, transparent 40%, #fafafa 100%);
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
