.all-student-wrapper {
  .student-item {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background: #f7f9f9;
    }
    .left-wrapper {
      display: flex;
      width: 70%;
      .avatar-wrapper {
        margin-right: 10px;
      }
      .info-wrapper {
        width: 100%;
        .info__name {
          margin-bottom: 0px;
          font-weight: 500;
          font-size: 14px;
          color: #0f1825;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info__subname {
          margin-bottom: 0px;
          font-weight: 400;
          font-size: 14px;
          color: #566370;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .time-wrapper {
      color: #566370;
      font-weight: 400;
      font-size: 14px;
      width: 40px;
      text-align: right;
    }
  }
  .student-item--active {
    background: #f7f9f9;
    border-radius: 10px;
  }
}
