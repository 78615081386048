.header {
  height: 72px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #e6eaf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right-wrapper {
    display: flex;
    align-items: center;
    .noti-wrapper {
      position: relative;
      cursor: pointer;
      transform: translateY(2px);
      .noti-icon {
      }
      .badge {
        background-color: #c83c00;
        color: white;
        position: absolute;
        top: -5px;
        right: -6px;
        height: 14px;
        width: 14px;
        font-size: 8px;
        text-align: center;
        font-weight: 700;
        border-radius: 50%;
      }
    }
    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: blue;
      cursor: pointer;
      background: center center / 100% no-repeat;
      height: 40px;
      width: 40px;
      margin-left: 24px;
    }
  }
  .left-wrapper {
    display: flex;
    align-items: center;
    .left-arrow-icon {
      margin-right: 14px;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }

    .icon-menu {
      display: none;
    }

    @media (max-width: 768px) {
      .icon-menu {
        display: block !important;
        margin-right: 20px;
      }
    }
  }
}

.menu-mb-items {
  .item-link-wrapper {
    text-decoration: none;
    height: 40px;
    width: 100%;
    border-radius: 12px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    text-decoration: none;
    color: #7b8698;
    font-weight: 500;
    font-size: 14px;
    transition: none;
    cursor: pointer;
    &:hover {
      color: none;
    }
    &.active {
      background-color: #2264f2;
      color: white;
    }
    .icon-wrapper {
      margin-right: 12px;
      display: flex;
      align-items: center;
    }

    svg {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
}

.dropdown-menu {
  width: 282px;
  border-radius: 10px;
  padding: 24px;
  border: 1px solid #e6eaf0;
  box-shadow: 0px 24px 24px -24px rgba(89, 101, 121, 0.25);
  .dropdown-item {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &:active {
      .dropdown-item-link {
        .dropdown-labels {
          .main-label {
            color: white;
          }
          .sub-label {
            color: #d9dee3;
          }
        }
      }
    }
    .dropdown-item-link {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      .dropdown-item-picture {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 16px;
      }
      .dropdown-labels {
        .main-label {
          color: #000;
          font-weight: 500;
          font-size: 14px;
        }
        .sub-label {
          font-size: 13px;
          color: #596579;
        }
      }
    }
  }
}
