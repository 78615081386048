@import 'theme/scss/variables';
.wrapper-toolbar {
  margin-bottom: 38px;
  .rbc-toolbar-label {
    display: flex;
    line-height: 36px;
  }

  .rbc-btn-group {
    display: flex;
    justify-content: flex-end;
    .add-calendar-btn {
      border-radius: 6px !important;
      padding: 8px 12px !important;
      .plus-btn-icon {
        float: left;
        margin-right: 8px;
      }
    }
  }

  .toolbar-label {
    color: $gray--900;
    font-weight: 600;
    margin: 0px 20px;
    font-size: 15px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    min-width: 100px;
    text-align: center;
  }

  .toolbar-icon {
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: #f7f9fc;
    border-radius: 50%;
    color: $gray--500;
    text-align: center;
    &:hover {
      opacity: 0.8;
    }
    .toolbar-icon-left {
      margin-right: 3px;
      margin-bottom: 3px;
    }

    .toolbar-icon-right {
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }

  .toolbar-today-btn {
    margin-left: 24px;
    border: 1px solid $color-blue--light;
    width: 60px;
    display: flex;
    justify-content: center;
    border-radius: 6px !important;
    &:hover {
      background-color: $color-blue;
      color: $gray--50 !important;
      border: 1px solid $color-blue--light;
    }
  }

  .pending-requests-wrapper {
    margin-right: 10px;
  }

  .pending-requests-toggle {
    background: #f4d8cc;
    color: #c83c00;
    border: none;
  }

  .request-pending-icon {
    color: #c83c00;
    margin-right: 6px;
    margin-top: -2px;
  }

  .select-view-calendar {
    margin: 0px 8px;
  }

  .icon-dropdown {
    float: left;
    color: $gray--500;
    margin-right: 6px;
    margin-top: 2px;
  }
}

@media (max-width: 1134px) {
  .wrapper-toolbar {
    .rbc-btn-group {
      .pending-requests-wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: 870px) {
  .wrapper-toolbar {
    .rbc-btn-group {
      display: none;
    }
  }
}
