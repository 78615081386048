.multi-select-wrapper {
  margin-bottom: 16px;
  font-size: 13px;
  color: #566370;
  font-weight: 500;
}
.wrapper-item-option {
  display: flex !important;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer !important;
  &:hover {
    background-color: #5663707b;
  }
}
