.student-select-wrapper {
  margin-bottom: 16px;
  font-size: 13px;
  color: #566370;
  font-weight: 500;
  .error-feedback {
    width: 100%;
    margin-top: 4px;
    font-size: 13px;
    color: #dc3545;
  }
}
.wrapper-item-option {
  display: flex !important;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer !important;
  &:hover {
    background-color: #5663707b;
  }
  .student-avatar {
    margin-right: 10px;
  }
  .student-name {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 13px;
    color: #0f1825;
    margin-right: 10px;
  }
  .student-email {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 13px;
    color: #566370;
  }
}
