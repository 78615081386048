.confirm-modal-body {
  padding: 24px;
}

@media (max-width: 768px) {
  .custom-modal-mb {
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
  }
}
