.user-info {
  .cover-area {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 28px;
    .avatar-area {
      position: relative;
      .progress-circle {
        position: absolute;
        height: 120px;
        width: 120px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .progress-badge {
        position: absolute;
        background-color: #ff9400;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #ffffff;
        border: 2px solid white;
        font-weight: 500;
        font-size: 18px;
        right: -15px;
        bottom: -10px;
      }
    }
    .more-icon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .name {
      margin-top: 38px;
      font-weight: 500;
      font-size: 16px;
      color: #0f1825;
    }
    .email {
      font-weight: 400;
      font-size: 13px;
      color: #566370;
    }
  }
  .options-area {
    margin-top: 24px;
    .option-item {
      border-top: 1px solid #f0f3f4;
      padding: 16px 0;
      display: flex;
      font-weight: 500;
      font-size: 14px;
      color: #374253;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 12px;
      }
      &:hover {
        color: #2264f2;
        transition: 0.1s color;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .user-info {
    .options-area {
      .option-item {
        padding: 16px 24px;
        border-left: 1px solid #f0f3f4;
        border-right: 1px solid #f0f3f4;
      }

      .option-item:first-child {
        border-radius: 10px 10px 0px 0px;
      }

      .option-item:last-child {
        border-bottom: 1px solid #f0f3f4;
        padding-bottom: 16px !important;
        border-radius: 0px 0px 10px 10px;
      }
    }
  }
  .user-info-mb {
    border: none !important;
    padding: 0px !important;
  }
}
