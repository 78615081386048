.input-field-group {
  margin-bottom: 16px;
  position: relative;
}

.input-field-input:global(.form-control) {
  height: 44px;
  border: 1px solid #d1d9dd;
  font-size: 13px;
  border-radius: 6px !important;
  box-shadow: none !important;
  padding-top: 16px;
  &:focus + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }
  &.has-value + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }
}

.input-field-label {
  font-size: 14px;
  font-weight: 500;
  font-size: 13px;
  color: #566370;
  font-weight: 500;
  position: absolute;
  top: 13px;
  left: 13px;
  transition: 0.15s;
  cursor: text;
  user-select: none;
}

.chevron-down-icon {
  color: #566370;
  position: absolute;
  right: 8px;
  top: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.calendar:global(.react-datepicker) {
  border-radius: 10px;
  box-shadow: 0px 3px 9px 1px rgba(52, 64, 76, 0.32);
  border: none;
  font-family: 'Inter';
  :global(.react-datepicker__day) {
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    &:hover {
      background: #e9f0fe;
      color: #2264f2;
    }
  }

  :global(.react-datepicker__day--selected) {
    background: #2264f2 !important;
    color: white !important;
  }
  :global(.react-datepicker__day-name) {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-weight: 500;
    font-size: 14px;
  }
  :global(.react-datepicker__header) {
    background-color: white;
    border-bottom: none;
    padding-bottom: 0;
  }
  :global(.react-datepicker__month) {
    margin-top: 0;
    position: relative;
  }
  :global(.react-datepicker__current-month) {
    font-weight: 500;
    font-size: 16px;
    color: #192638;
    height: 40px;
    padding-top: 10px;
  }
  :global(.react-datepicker__navigation) {
    top: 20px;
  }
  :global(.react-datepicker__time-box) {
    width: 100px;
  }
  :global(.react-datepicker__header--time--only) {
    width: 100px;
  }
}
