@import 'theme/scss/variables';
.wrapper-toolbar {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-left: 1px solid #e6eaf0;
  .rbc-toolbar-label {
    display: flex;
    line-height: 36px;
  }

  .toolbar-label {
    color: $gray--900;
    font-weight: 600;
    margin: 0px 20px;
    font-size: 15px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    min-width: 100px;
    text-align: center;
  }

  .toolbar-icon {
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: #f7f9fc;
    border-radius: 50%;
    color: $gray--500;
    text-align: center;
    &:hover {
      opacity: 0.8;
    }
    .toolbar-icon-left {
      margin-right: 3px;
      margin-bottom: 3px;
    }

    .toolbar-icon-right {
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }
}
