.billing-wrapper {
  margin-bottom: 36px;
  padding: 24px 0px 0px 24px;
  .billing-header {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 15px;
      color: #0f1825;
    }
    .btn-view-billing {
      text-decoration: none !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 28px;
    }
  }

  .billing-body {
    display: flex;
    align-items: flex-start;
    border: 1px solid #f0f3f4;
    border-radius: 10px;
    .billing-item {
      flex-grow: 1;
      padding: 24px;
      border-right: 1px solid #f0f3f4;
      .label {
        font-weight: 500;
        font-size: 12px;
        color: #596579;
        margin-bottom: 8px;
      }
      .value {
        font-weight: 500;
        font-size: 16px;
        color: #0f1825;
        margin-bottom: 0px;
      }
    }

    .billing-item:last-child {
      border-right: none;
    }
  }
}

@media (max-width: 768px) {
  .billing-wrapper {
    padding: 30px 15px 0px 15px;
    .billing-body {
      display: block;
      .billing-item {
        border-bottom: 1px solid #f0f3f4;
        border-right: none;
      }
      .billing-item:last-child {
        border-bottom: none;
      }
    }
  }
}
