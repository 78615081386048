@tailwind utilities;

/* placing in utilities adds an additional selector
 * which is required to override Bootstrap styles */
@layer utilities {
  .btn {
    @apply px-4 py-2.5 border-0 rounded-full text-xs leading-5 font-medium flex justify-center items-center gap-1.5 transition-colors;
    @apply active:outline active:outline-2 active:outline-indigo-200 active:shadow-none;
    @apply focus:!outline focus:!outline-2 focus:!outline-indigo-200 focus:shadow-none;
    @apply disabled:bg-gray-100 disabled:text-gray-500 disabled:pointer-events-none;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-accent-dark-blue;
  }

  .btn-secondary {
    @apply bg-indigo-100 !text-primary;
    @apply hover:!bg-indigo-200;
    @apply active:!bg-indigo-100;
    @apply focus-visible:!bg-indigo-100;
  }

  .btn-outline {
    @apply bg-white text-gray-900 shadow-btn-outline;
    @apply hover:!bg-white;
    @apply active:!bg-white;
    @apply focus-visible:!bg-white;
  }

  .btn-light {
    @apply bg-gray-100 text-gray-600 hover:bg-gray-200;
  }

  .btn.busy {
    overflow: hidden;
    pointer-events: none;
    text-indent: 9999%;
    white-space: nowrap;
  }

  .btn.busy:before {
    @apply animate-spin text-indigo-200;
    border: 0.125em solid;
    border-radius: 9999px;
    border-bottom-color: var(--tw-color-primary);
    border-right-color: var(--tw-color-primary);
    content: '';
    height: 1em;
    position: absolute;
    width: 1em;
  }

  .btn-primary.busy:before {
    @apply text-indigo-300 border-r-white border-b-white;
  }
}
