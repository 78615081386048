.assignments-wrapper {
  margin-bottom: 36px;
  padding-left: 24px;
  .assignments-header {
    font-weight: 500;
    font-size: 15px;
    color: #0f1825;
    margin-bottom: 16px;
  }

  .assignments-body {
    border: 1px solid #f0f3f4;
    border-radius: 10px;
    padding: 44px;
    display: flex;
    justify-content: center;
    .assignments-no-item {
      width: 382px;
      .no-item__title {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #0f1825;
      }
      .no-item__desc {
        font-size: 13px;
        text-align: center;
        color: #596579;
        margin-bottom: 0px;
      }
    }
  }
}

@media (max-width: 768px) {
  .assignments-wrapper {
    padding: 0px 15px;
    .assignments-body {
      padding: 44px 20px;
    }
  }
}
