@import 'theme/scss/variables';
.table-assignments-wrapper {
  .header {
    display: flex;
    border: 1px solid #f0f3f4;
    overflow: auto;
    border-radius: 10px;

    .no-content {
      padding: 15px;
      text-align: center;
      width: 100%;
      font-weight: bold;
      font-size: 15px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .student-name {
      width: 270px;
      height: 250px;
    }

    .student-info {
      cursor: pointer !important;
      &:hover {
        background-color: #f7f9fc;
      }
      padding: 16px 8px;
      max-width: 270px;
      display: flex;
      flex: 0 0 270px;
      align-items: center;
      border-top: 1px solid #f0f3f4;
      color: #0f1825;
      font-weight: 500;
      font-size: 14px;
    }

    .wrapper-task {
      overflow-y: auto;
      display: flex;
    }

    .assignment-col {
      border-left: 1px solid #f0f3f4;
    }

    .assignment-progress {
      width: 56px;
      position: relative;
      height: 64px;
      border-top: 1px solid #f0f3f4;

      .assignment-status {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .checked-icon {
          color: #54ab00;
        }

        .clock-icon {
          color: #596579;
        }

        .add-icon {
          color: #2264f2;
        }
      }

      .empty {
        display: none;
      }
    }

    .assignment-progress-none:hover {
      border: 1px dotted #2264f2;
      cursor: pointer;
      .empty {
        display: block !important;
      }
    }

    .finished {
      background: #54ab001a;
    }

    .in-progress {
      background-color: #f7f9fc;
    }

    .error {
      background-color: #c83c00;
    }

    .assignment-info {
      width: 56px;
      position: relative;
      height: 250px;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;

      .assignment-title {
        writing-mode: vertical-rl;
        transform: translateX(-50%) rotate(180deg);
        position: absolute;
        bottom: 10px;
        left: 50%;
        color: #0f1825;
        font-size: 13px;
        font-weight: 500;
        max-height: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .assignment-col-f {
      flex: 1;
      border-left: 1px solid #f0f3f4;

      .assignment-info-f {
        height: 250px;
      }

      .assignment-progress-f {
        height: 64px;
        border-top: 1px solid #f0f3f4;
      }
    }
  }
}
