.student-wrapper {
  padding: 24px 76px;
  .header {
    display: flex;
    justify-content: space-between;
    .left-wrapper {
      .search-input {
        width: 224px;
      }
      .check-box-custom-wrapper {
        margin-left: 16px;
      }
    }

    .right-wrapper {
      display: flex;
      align-items: center;
      .add-btn {
        margin-right: 24px;
      }
      .group-dropdown-wrapper {
        display: flex;
        align-items: center;
        .group-dropdown {
          margin-right: 8px;
        }
      }
      .filter-btn {
        display: none;
      }
      .remove-btn {
        margin-right: 16px;
      }
    }
  }
  .body {
    .spinner-wrapper {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .spinner {
        color: #2264f2;
      }
    }
    .add-more-banner {
      background-color: #f7f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-radius: 6px;
      .add-more-text {
        color: #566370;
        font-weight: 500;
        font-size: 13px;
        .add-more-action {
          color: #2264f2;
          cursor: pointer;
          font-weight: 600;
        }
      }
      .icon {
        cursor: pointer;
      }
    }
  }
}

.btn-icon {
  margin-right: 6px;
  transform: translateY(1px);
}

@media (max-width: 980px) {
  .student-wrapper {
    .header {
      .right-wrapper {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .student-wrapper {
    padding: 38px 15px;
    .left-wrapper {
      display: none;
    }
    .right-wrapper {
      margin-bottom: 24px;
      justify-content: space-between;
      width: 100%;
      .group-dropdown-wrapper {
        display: none !important;
      }
      .filter-btn {
        display: block !important;
        border-radius: 6px !important;
        padding: 0px 16px !important;
        border: 1px solid #d1d9dd;
        .btn-icon-filter {
          margin-left: 6px;
        }
      }
    }
  }
}
