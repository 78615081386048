@import 'theme/scss/variables';

.lessons-wrapper {
  padding: 0px !important;
  .spinner {
    color: #2264f2;
    margin-left: 50%;
  }
  .no-lesson {
    height: 100px;
    padding: 45px;
    width: 100%;
    text-align: center;
    position: relative;
    font-size: $text-sm;
    color: $gray--900;
    font-weight: 500;
  }
  .table-wrapper {
    & > table {
      width: 100%;
      & > thead {
        & > tr {
          .header-1 {
            width: 1000px;
          }
          .header-2 {
            width: 40px;
          }
        }
      }

      & > tbody {
        & > tr {
          border-bottom: 1px solid $gray--border;
          & > td {
            padding: 16px;
          }

          .wrapper-colume-1 {
            display: flex;
            .colume-1--left {
              border: 1px solid $gray--outline;
              padding: 6px;
              border-radius: 8px;
              width: 44px;
              text-align: center;
              margin-right: 16px;
              .day {
                font-size: $text-base;
                font-weight: 500;
                width: 32px;
              }
              .month {
                font-size: $text-xs;
                font-weight: 400;
                width: 32px;
              }
            }

            .colume-1--right {
              padding: 8px 0px;
              .time-label {
                font-weight: bold;
              }
              .title {
                font-size: $text-sm;
                color: $gray--900;
                font-weight: 500;
              }

              .date {
                font-size: $text-normal;
                color: $text-secondary;
                font-weight: 500;
              }
            }
          }

          .colume-2 {
            display: flex;
            justify-content: flex-end;
            .icon {
              cursor: pointer;
              &:hover {
                & > path {
                  fill: $color-blue;
                }
              }
            }
          }
        }
      }
    }
  }
}
