.student-list-wrapper {
  .students-info-wrapper {
    list-style: none;
    padding-left: 0px;
    li {
      display: block;
      float: none;
      overflow: hidden;
      text-overflow: ellipsis;
      .student-info {
        padding: 16px;
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid transparent;
        border-top-color: #e0e0e0;
        border-radius: 0;
        cursor: pointer;
        .student-info__avatar {
          margin-right: 10px;
          width: 25px;
        }
        .student-info__name {
          font-weight: 500;
          font-size: 14px;
          color: #0f1825;
          margin-bottom: 0px;
        }
      }

      &.active + li:not(:hover),
      &:hover + li:not(.active),
      &:first-child {
        .student-info {
          border-color: transparent;
        }
      }

      &:hover {
        .student-info {
          background: #e0e0e0;
          border-color: transparent;
          border-radius: 4px;
        }
      }

      &.active {
        .student-info {
          background: #f8fbfd;
          border-color: #e0e0e0;
          border-radius: 4px;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
          color: inherit;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .notes-wrapper {
  }
}
