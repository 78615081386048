svg.icon.check {
  @apply stroke-[3px] stroke-primary;
}

.pricing-table td:first-child {
  @apply text-left whitespace-nowrap w-[440px];
}

.pricing-table td {
  @apply px-6 py-4 text-center border-b border-r h-16;
}

.pricing-table td:last-child {
  @apply border-r-0;
}

.pricing-table tr:last-child td {
  @apply border-b-0;
}
