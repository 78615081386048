.user-guide-wrapper {
  position: fixed;
  width: 288px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 16px 72px -10px rgba(86, 99, 112, 0.3);
  border-radius: 16px;
  bottom: 145px;
  left: 20px;
  .header {
    font-weight: 500;
    font-size: 13px;
    color: #0f1825;
  }
  .progress-wrapper {
    display: flex;
    margin: 16px 0px;
    .progress {
      width: calc(25% - 4px);
      height: 4px;
      border-radius: 24px;
      margin-right: 4px;
    }

    .progress--actived {
      background: #2264f2;
    }
    .progress--inactive {
      background: #e6eaf0;
    }
  }
  .to-do {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    &-radio {
      margin-right: 10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    &-radio--actived {
      background: #e6eaf0;
      color: #7b8698 !important;
    }
    &-radio--inactive {
      border: 1.5px solid #d1d9dd;
    }
    &-content {
      cursor: pointer;
      .label {
        font-weight: 400;
        font-size: 13px;
        color: #2264f2;
        margin-bottom: 0px;
        width: 210px;
      }

      .sub-label {
        font-weight: 400;
        font-size: 12px;
        color: #596579;
        margin-bottom: 0px;
        width: 210px;
      }

      .label--actived {
        font-weight: 400;
        font-size: 13px;
        color: #374253;
        margin-bottom: 0px;
        width: 210px;
      }

      &:hover {
        text-decoration: underline;
        color: #2264f2;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    .btn-skip {
      background: #e9f0fe;
      padding: 6px 6px 6px 12px !important;
      border: none;
      &:hover {
        background: #2264f2;
      }
      .skip-btn-icon {
        float: right;
        margin-left: 5px;
      }
    }
  }
  .user-guide-complete {
    text-align: center;
    img {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .user-guide-complete-heading {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 10px;
    }
    .user-guide-complete-description {
      color: #596579;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 40px;
      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .user-guide-wrapper {
    bottom: 20px;
  }
}
