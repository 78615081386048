@import 'theme/scss/variables';
.modal-container {
  padding: 15px;
  :global(.modal-dialog) {
    :global(.modal-content) {
      width: 150%;
    }
  }
  .text-center {
    text-align: center;
  }
}
