.wrapper-body {
  margin-top: 4px;
  .body-title {
    font-weight: 600;
    font-size: 16px;
    color: #141416;
    padding: 0px 16px;
    margin-bottom: 0px !important;
  }
  .body-content {
    display: flex;
    padding: 16px;
    .line {
      height: 36px;
      width: 1px;
      background-color: #e6eaf0;
      margin-top: 2px;
    }

    .content__composer {
      margin-left: 14px;
    }
    .content__composer,
    .content__time {
      padding-right: 24px;
      .title {
        color: #777e90;
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 0px !important;
      }
      .content {
        font-weight: 500;
        font-size: 14px;
        color: #141416;
        margin-bottom: 0px !important;
      }
    }
  }

  .card-attachments {
    .attachment-item {
      width: 100%;
      margin-top: 8px;
    }

    .wrapper-audio {
      padding: 8px 0px;

      & > div:not(:first-child) {
        display: none;
      }
    }

    .wrapper-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2264f2;
      margin-bottom: 16px;
      .controls-icon {
        cursor: pointer;
        margin: 0px 8px;
      }

      .controls-icon:hover {
        opacity: 0.8;
      }

      .controls-icon__disabled {
        cursor: not-allowed !important;
        opacity: 0.5 !important;
      }
    }

    .icon-warning {
      color: #c83c00;
      position: absolute;
      top: -8px;
      right: 32px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }

  .card-practice-level {
    margin: 16px 0px;
    height: 44px;
    text-align: center;
    line-height: 44px;
    background: #e3edf4;
    color: #2170a9;
    display: flex;
    justify-content: center;
    .cup-icon {
      margin-top: 12px;
      margin-right: 8px;
    }
  }
}
