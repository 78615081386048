.message-area-wrapper {
  height: 100%;
  .message-area-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f3f4;
    .header-info {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 10px;
      }
      .name {
        font-weight: 500;
        font-size: 15px;
        color: #0f1825;
      }
    }
    .header-action {
      display: flex;
      align-items: center;
      .trash-icon {
        margin-right: 18px;
        cursor: pointer;
        &:hover {
          fill: #c83c00 !important;
        }
      }
      .more-icon {
        margin-right: 32px;
        cursor: pointer;
        &:hover {
          color: #2264f2;
        }
      }
    }
  }
  .message-area-body {
    height: calc(100vh - 305px);
    display: table-cell;
    vertical-align: bottom;
    width: 100vw;
    .messages-wrapper {
      overflow: auto;
      max-height: calc(100vh - 305px);
      padding-top: 24px;
      .message-sent-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px 8px;
        .message-sent {
          background: #2264f2;
          border-radius: 36px 16px 2px 36px;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          margin-bottom: 4px;
          max-width: 298px;
          padding: 10px 14px;
        }
      }
      .message-received-wrapper {
        padding: 0px 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .message-received {
          background: #f0f3f4;
          border-radius: 16px 32px 32px 2px;
          font-weight: 400;
          font-size: 14px;
          color: #0f1825;
          margin-bottom: 4px;
          max-width: 298px;
          padding: 10px 14px;
        }
      }
      .message-sent-wrapper,
      .message-received-wrapper {
        .message-time {
          font-weight: 400;
          font-size: 12px;
          color: #566370;
        }
      }
    }
  }
  .message-area-footer {
    padding: 24px;
    .input-wrapper {
      border: 1px solid #e6eaf0;
      border-radius: 16px;
      .input {
        border: none;
        margin-top: 16px;
        padding: 0px 16px;
        resize: none;
        min-height: 30px;
        height: 30px;
      }
      .action {
        margin-bottom: 16px;
        &-icon-paper,
        &-icon-calendar {
          cursor: pointer;
          &:hover {
            stroke: #2264f2;
          }
        }
        &-icon-paper {
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }
  }
}
