@import 'theme/scss/variables';

.goal-wrapper {
  .spinner {
    color: #2264f2;
    margin-left: 50%;
  }
  .no-goal {
    height: 100px;
    padding: 45px;
    width: 100%;
    text-align: center;
    position: relative;
    font-size: $text-sm;
    color: $gray--900;
    font-weight: 500;
  }
  .goal-container {
    cursor: pointer !important;
    &:hover {
      background-color: #f7f9fc;
    }
  }
  .text-area {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .text-left {
      font-weight: 550;
      font-size: 13px;
      color: #0f1825;
    }
    .text-right {
      color: #566370;
      font-weight: 500;
      font-size: 13px;
    }
  }
  .progress {
    height: 8px;
  }
  .button-wrapper {
    padding-top: 16px;
    .new-goal-btn {
      border-radius: 6px !important;
      .plus-icon {
        float: left;
      }
    }
  }
}

@media (max-width: 768px) {
  .goal-wrapper {
    .text-area {
      margin-bottom: 10px !important;
    }
  }
}
