.notes-wrapper {
  padding: 24px 76px;
}

.spinner-wrapper {
  margin-top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    color: #2264f2;
  }
}

@media (max-width: 768px) {
  .notes-wrapper {
    padding: 38px 15px;
  }
}
