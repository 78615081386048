@import 'theme/scss/variables';
.avatar-field-wrapper {
  .avatar-wrapper {
    height: 41px;
    width: 41px;
    border-radius: 50%;
    margin-right: 12px;
    //overflow: hidden;
    //position: relative;

    //img {
    //  height: 100%;
    //  position: absolute;
    //  top:50%;
    //  left:50%;
    //  transform: translate(-50%,-50%);
    //}
  }

  .avatar-img {
    cursor: pointer;
  }

  .avatar-input {
    display: none !important;
  }

  .avatar-button {
    cursor: pointer;
    color: $color-blue;
    font-weight: 600;
    font-size: 13px;
    &:hover {
      text-decoration: underline;
    }
  }
}
