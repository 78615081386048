.assignment-modal-body {
  padding: 24px;
  .description-input {
    height: 80px;
  }
  .sub-title {
    color: #566370;
    font-weight: 500;
    font-size: 13px;
  }
  .assignment-type-wrapper {
    margin-bottom: 16px;
    .label {
      font-weight: 500;
      font-size: 13px;
      color: #0f1825;
      margin-bottom: 4px;
    }
    .assignment-type {
      width: 100%;
    }
  }
  .assignment-list {
    display: flex;
    margin-top: 16px;
    overflow-x: auto;
    padding: 16px 0px;
    .assignment-item {
      display: flex;
      align-items: center;
      height: 36px;
      background: #e9f0fe;
      border-radius: 30px;
      margin-right: 10px;
      padding: 12px;
      color: #2264f2;
      font-weight: 500;
      font-size: 13px;
      cursor: pointer;
      &.active {
        background-color: #2264f2;
        color: white;
      }
      .icon {
        margin-right: 10px;
      }
      .title {
        width: max-content;
      }
    }
  }
  .assignment-more-assignment {
    margin-top: 24px;
    .assignment-more-item-wrapper {
      padding-top: 20px;
      border-top: 1px solid #f0f3f4;
      border-bottom: 1px solid #f0f3f4;
      &:last-child {
        border-bottom: none;
      }
      .duration-units {
        width: 100%;
        height: 44px;
      }
      .interval {
        width: 100%;
        height: 44px;
        margin-bottom: 16px;
      }
      .subtask-wrapper {
        display: flex;
        .subtask-input {
          width: 100%;
          .input {
            border-radius: 6px 0px 0px 6px !important;
          }
        }
        .icon-delete {
          width: 44px;
          height: 44px;
          color: #fff;
          background: #d43f3d;
          padding: 10px 0px;
          cursor: pointer;
          border-radius: 0px 6px 6px 0px !important;
          &:hover {
            background: #db3937;
          }
        }
      }
      .thumbnail-youtube {
        margin-bottom: 16px;
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .assignment-modal-body {
      padding: 20px 15px !important;
    }
    .content-modal-mb {
      height: 100vh !important;
    }
  }
}
