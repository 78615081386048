.add-student-modal {
  width: 600px;
  .add-student-modal-body {
    .required-section {
      padding: 20px 24px;
    }
    .optional-section {
      padding: 20px 24px;
      background-color: #f7f9f9;
      .section-header {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 14px;
      }
    }
  }
}

.add-student-btn-icon {
  margin-right: 6px;
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .content-modal-mb {
      height: 100vh !important;
      .required-section {
        padding: 24px 15px;
      }
      .optional-section {
        padding: 24px 15px;
      }
    }
  }
}
