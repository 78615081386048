@use 'sass:color';

:global {
  .form-control {
    &[name='summary'] {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .dropdown-toggle::after {
    content: none !important;
  }
}

.form {
  height: 100%;
}

.wrapper {
  right: 0;
  top: 0;
  height: 100%;
  font-size: 13px;
  width: 100%;
  box-shadow: 0px 44px 72px -10px #5663704d;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .header {
    border-bottom: 1px solid #e6eaf0;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input {
      margin-bottom: 0;
      input {
        font-size: 16px;
        border: none;
        min-width: 100px;
        border-radius: 0;
        min-height: 0;
        padding: 5px;
        border-bottom: 1px solid #e6eaf0;
      }
    }
  }

  :global {
    .react-datepicker-popper {
      z-index: 3;
    }
  }
}
.body {
  height: 100%;
  width: 100%;
  form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.stack {
  display: flex;
  align-items: start;
  padding: 13px 24px;
  flex: 0 !important;
}

.footer {
  padding: 13px 24px;
  display: flex;
  margin-top: auto;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid #e6eaf0;

  button {
    background-color: #2264f2;
    padding: 10px 20px;
    border-radius: 20px;
    color: white;

    &:hover {
      background-color: scale-color($color: #2264f2, $lightness: -8%);
    }
  }
}

.categoryOptionWrapper {
  display: flex;
  align-items: center;
  .dot {
    font-size: 20px;
    margin-right: 5px;
  }
}
