.add-group-modal-body {
  padding: 10px 24px;
  max-height: 500px;
  overflow-y: auto;
  .group-item {
    padding: 10px 0;
    display: flex;
    .left-wrapper {
      margin-right: 10px;
    }
    .right-wrapper {
      .name {
        margin-left: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #0f1825;
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-modal-mb {
    min-height: 0px !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: 100vw !important;
    .content-modal-mb {
      height: 100vh !important;
    }
  }
}
