.input-field-group {
  margin-bottom: 16px;
  position: relative;
  & > div:focus-within {
    box-shadow: none;
  }
}

.input-field-input:global(.form-control) {
  height: 44px;
  border: 1px solid #d1d9dd;
  font-size: 13px;
  border-radius: 6px !important;
  box-shadow: none !important;
  padding-top: 16px;
  &.small {
    height: 36px;
    padding-top: 6px;

    &:focus + .input-field-label {
      display: none;
    }
  }
  &:focus + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }
  &.has-value + .input-field-label {
    font-size: 9px;
    top: 4px;
    left: 14px;
  }
}

.tag-input-wrapper {
  width: 100%;

  &.is-invalid {
    :global(.rti--container) {
      border-color: #dc3545;
    }
  }
  :global {
    .rti--container {
      box-shadow: none !important;
      .rti--input {
        width: 100%;
      }
    }
  }
}
