.feed-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  .spinner {
    color: #2264f2;
    margin: 3em;
  }
  .load-more {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
