.page-section {
  .title {
    font-weight: 500;
    font-size: 15px;
    color: #0f1825;
    margin-bottom: 16px;
  }
  .section-wrapper {
    padding: 20px 24px;
    border: 1px solid #f0f3f4;
    border-radius: 10px;
  }
}
