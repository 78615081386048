$open-sans: 'Open Sans', Helvetica, sans-serif;
$source-sans-pro: 'Source Sans Pro', Helvetica, sans-serif;

$color-blue: #2170a9;

// Spinners

$spinner-width: 2rem !default;
$spinner-height: $spinner-width !default;
$spinner-border-width: 0.25em !default;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;

// color
$gray--50: #fafbfc;
$gray--500: #7b8698;
$gray--600: #596579;
$gray--900: #0f1825;
$text-secondary: #566370;
$color-orange: #ff9400;
$color-orange--50: #ff94001a;
$color-blue: #2264f2;
$color-blue--light: #d3e0fc;
$color-blue--light-10: #e9f0fe;
$gray--border: #f0f3f4;
$gray--outline: #d1d9dd;
$white: #ffffff;
$color-red: #c83c00;
$color-green: #4ba356;

// font-size
$text-xs: 12px;
$text-normal: 13px;
$text-sm: 14px;
$text-base: 16px;
$text-lg: 18px;
$text-xl: 20px;
