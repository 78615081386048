@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

:global {
  .form-check.form-switch {
    .form-check-input {
      height: 1.25rem;
      width: 2.25rem;
    }
  }
}

.wrapper {
  padding: 10px 0px;
  border-top: 1px solid #e6eaf0;
  border-bottom: 1px solid #e6eaf0;
  padding: 13px 24px;
}

.checkbox-container {
  @extend .wrapper;
  border-top: none;
  background-color: #fafbfc;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 10px;
  }
}

.modal-container {
  padding: 24px;
  .checkbox-container {
    background-color: white;
    padding: 0;
    border-bottom: 0;
  }
}
