.wrapper-footer {
  .card-react {
    display: flex;
    justify-content: space-evenly;
    height: 60px;
    border-top: 1px solid #e6eaf0;
    .react-item {
      width: 50%;
      text-align: center;
      height: 60px;
      line-height: 40px;
      font-weight: 500;
      font-size: 13px;
      padding: 10px 0px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .icon-heart,
      .icon-comment {
        color: #596579;
        margin-right: 6px;
        margin-bottom: 2px;
      }

      .icon-comment {
        margin-bottom: 0px;
      }

      .icon-heart__active {
        margin-right: 6px;
        margin-bottom: 2px;
        color: #2264f2;
      }
    }
    .react-item:hover {
      background-color: #5965791a;
    }
    .react-item__active {
      color: #2264f2;
      background-color: #2264f21a;
      width: 80px;
      height: 40px;
      border-radius: 36px;
    }
    .line {
      height: 40px;
      background: #e6eaf0;
      width: 1px;
      margin-top: 10px;
    }
  }
  .card-comment {
    .wrapper-comment {
      display: flex;
      padding: 0px 16px;
      margin-top: 18px;
      .comment__avatar {
        margin-right: 10px;
      }
      .comment__content {
        padding: 8px 12px;
        background: #f7f9fc;
        border-radius: 16px;

        .content__name {
          color: #0f1825;
          font-weight: 600;
          font-size: 13px;
        }

        .content__time {
          color: #7b8698;
          font-weight: 500;
          font-size: 13px;
        }

        .content__message {
          font-weight: 500;
          font-size: 14px;
          color: #0f1825;
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .card-input {
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0px 16px;
    display: flex;
    align-items: flex-end;
    .input__avatar {
      margin-right: 10px;
    }
    .comment-input-wrapper {
      background: #f7f9fc;
      border-radius: 24px;
      height: 44px;
      display: flex;
      width: 100%;

      .comment-input {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 0px;
        margin-left: 8px;
      }

      .comment-input-field {
        padding: 8px !important;
        min-height: 32px !important;
        background: #f7f9fc;
        border: none;
        border-radius: 24px;
      }

      .input-icon-send,
      .input-icon-emoji {
        color: #2264f2;
        margin-top: 10px;
        cursor: pointer;
      }

      .input-icon-send {
        margin-right: 13px;
        margin-left: 10px;
      }

      .input-icon-emoji:hover {
        color: blue;
      }

      .comment-input-spiner {
        color: #2264f2;
        margin-top: 13px;
        margin-right: 13px;
        margin-left: 10px;
      }

      .dropdown-menu {
        padding: 0px !important;
        border: none;
      }
    }
  }
}
